import cx from 'classnames';
import PropTypes from "prop-types";
import React from "react";

import styles from "./Constrainer.module.css";

const Constrainer = ({
	overrideStyles = '',
	additionalStyles = '',
	children = [],
	...props
}) => {

	const applyStyles = () => {
		return overrideStyles
			? overrideStyles
			: cx(styles.container, additionalStyles ? additionalStyles : '');
	};

	return (
		<div
			className={applyStyles()}
			{...props}
		>
			{children}
		</div>
	);
}

Constrainer.propTypes = {
	additionalStyles: PropTypes.string,
	overrideStyles: PropTypes.string,
	pageComponent: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default Constrainer;