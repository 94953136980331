import React from 'react';
import translations from "assests/translations.json";
import { useLanguageContext } from "context/LanguageContext.js";
import Typography from '@material-ui/core/Typography';

const Content = (props) => {
	const [ language ] = useLanguageContext();

	const {
		contentKey,
		elementType,
		...rest
	} = props;


	const ElementType = elementType === ('Typography' || 'typography')
		? Typography
		: elementType;

	return (
		<ElementType {...rest}>
			{translations[ language ][ contentKey ]}
		</ElementType>
	);
};

export default Content;
