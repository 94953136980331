import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import TopBarNav from "./default-layout-components/TopBarNav.js";
import Footer from './default-layout-components/Footer.js';
import defualtPageLayoutStyle from "./DefualtPageLayout.module.css";

const DefualtPageLayout = ({
	pageName,
	pageComponent: children = [],
}) => {

	const ScrollToTopOnMount = () =>{
		useEffect(() => {
		  window.scrollTo(0, 0);
		}, []);
		return null;
	  }

	return (
		<>
		 	<ScrollToTopOnMount />
			<TopBarNav />
			<div
				id={pageName}
				className={defualtPageLayoutStyle.container}
			>
				{children}
			</div>
			<Footer />
		</>
	);
}

DefualtPageLayout.propTypes = {
	pageName: PropTypes.string,
	pageComponent: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default DefualtPageLayout;