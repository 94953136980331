import cx from 'classnames';
import React from 'react';

import contactUsStyles from "./Contact.module.css";
import ourTeamStyles from "./OurTeam.module.css";

import Typography from '@material-ui/core/Typography';
import typography from 'styles/Typography.module.css';

import gallery from "./Gallery.module.css";
import Container from '@material-ui/core/Container';
import Content from "components/gallery/Content.js";
import CardMedia from '@material-ui/core/CardMedia';

import placeholder from "media-assets/team/placeholder.jpg";
import juan_carlos from "media-assets/team/juan_carlos.jpg";
import kelvyn_munzon from "media-assets/team/kelvyn_munzon.jpg";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const OurTeam = () => {
	return (
		<Container className={gallery.container} component="section">
			<Content
				elementType={"Typography"}
				contentKey={'our-team-header-main'}
				gutterBottom
				variant="h2"
				component="h2"
				className={cx(typography.header, typography.center)}
			/>

			<div className={cx(contactUsStyles.panel, ourTeamStyles.panelOverride)}>
				<Card className={ourTeamStyles.cardOverride} sx={{ maxWidth: 345 }}>
					<CardMedia
						component="img"
						height="140"
						image={placeholder}
						alt="place holder img"
					/>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div" className={typography.center}>
							Juan Carlos
						</Typography>
						<Content
							elementType={"Typography"}
							contentKey={'project-manager-label'}
							gutterBottom
							variant="body2" 
							color="text.secondary"
							className={typography.center}
						/>
					</CardContent>
				</Card>
				<Card className={ourTeamStyles.cardOverride}>
					<CardMedia
						component="img"
						height="140"
						image={kelvyn_munzon}
						alt="green iguana"
					/>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div" className={typography.center}>
							Luis Munzon
						</Typography>
						<Content
							elementType={"Typography"}
							contentKey={'general-contractor-label'}
							gutterBottom
							variant="body2" 
							color="text.secondary"
							className={typography.center}
						/>
					</CardContent>
				</Card>
			</div>
		</Container>
	);
}

OurTeam.propTypes = {
};

export default OurTeam;