import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import DefualtPageLayout from './layouts/DefualtPageLayout.js';
import Home from './pages/Home.js';
import './App.css';
import Language from 'context/LanguageContext.js';

const App = () => {
  return (
    <Language>
      <Switch>
        <Route
          exact
          path={"/"}
          render={props =>
            <DefualtPageLayout
              pageName={'home'}
              pageComponent={<Home />}
            />}
        />
        <Route component={(props) =>
          <Redirect to="/" />
        } />
      </Switch>
    </Language>
  );
};

export default App;