import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import footerStyle from "./Footer.module.css";
import Constrainer from "../reusables/Constrainer.js";
import constrainerStyle from "../reusables/Constrainer.module.css";

import Button, { primary } from "../reusables/forms/Button.js";
import modalStyles from "../reusables/Modal.module.css";
import Modal from "../reusables/Modal.js";
import {
    FaInstagram,
    FaFacebookSquare,
    FaTwitterSquare,
    FaYoutubeSquare,
} from "react-icons/fa";
import topBarNav from "./TopBarNav.module.css";
import Content from "components/gallery/Content.js";

const Footer = () => {
    const [modalActive, setModalActive] = useState(false);

    const openModal = () => {
        setModalActive(true);
    }

    const closeModal = () => {
        setModalActive(false);
    }

    return (
        <div className={footerStyle.container}>
            <Constrainer additionalStyles={cx(constrainerStyle.flexColumn, footerStyle.noMarginTop)}>
                <div className={footerStyle.content}>
                    <div>
                        <Content
                            elementType={'h2'}
                            contentKey={'footer-column-header-main-links'}
                            className={footerStyle.columnHeader}
                        />
                        <ul className={footerStyle.linksList}>
                            <NavLink
                                to="#hero"
                                smooth
                                activeClassName={footerStyle.active}
                            >
                                <Content
                                    elementType={'li'}
                                    contentKey={'footer-link-label-home'}
                                />
                            </NavLink>
                            <NavLink
                                to="#about"
                                smooth
                                activeClassName={footerStyle.active}
                            >
                                <Content
                                    elementType={'li'}
                                    contentKey={'footer-link-label-about-us'}
                                />
                            </NavLink>
                            <NavLink
                                to="/#gallery"
                                smooth
                                activeClassName={footerStyle.active}
                            >
                                <Content
                                    elementType={'li'}
                                    contentKey={'footer-link-label-services'}
                                />
                            </NavLink>
							<NavLink
                                to="/#team"
                                smooth
                                activeClassName={footerStyle.active}
                            >
                                <Content
                                    elementType={'li'}
                                    contentKey={'footer-link-label-team'}
                                />
                            </NavLink>
                        </ul>
                    </div>

                    <div>
                        <Content
                            elementType={'h2'}
                            contentKey={'footer-column-header-main-social-media'}
                            className={footerStyle.columnHeader}
                        />
                        <ul className={cx(footerStyle.linksList, footerStyle.socialMedia)}>
                            <a href="https://www.facebook.com/" target="_blank">
                                <li>
                                    <FaFacebookSquare className={topBarNav.menuIcon} />
                                </li>
                            </a>
                            <a href="https://www.instagram.com/" target="_blank">
                                <li>
                                    <FaInstagram className={topBarNav.menuIcon} />
                                </li>
                            </a>
                            <a href="https://twitter.com/" target="_blank">
                                <li>
                                    <FaTwitterSquare className={topBarNav.menuIcon} />
                                </li>
                            </a>
                            <a href="https://www.youtube.com/" target="_blank">
                                <li>
                                    <FaYoutubeSquare className={topBarNav.menuIcon} />
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </Constrainer>
        </div>
    );
}

Footer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default Footer;