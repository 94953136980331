import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import pageStyle from "./Page.module.css";
import homeStyle from "./Home.module.css";
import Typography from '@material-ui/core/Typography';
import topBarNavStyle from "layouts/default-layout-components/TopBarNav.module.css";
import gallery from "./Gallery.module.css";
import Container from '@material-ui/core/Container';

import FSSC_logo from 'media-assets/logo.svg';

const Hero = () => {
    return (
        <div className={pageStyle.container}>
            <div className={homeStyle.hero} >
                <Container className={cx(gallery.container, homeStyle.heroContainer)} component="section">
                    <img src={FSSC_logo} className={homeStyle.image}/>
                </Container>
            </div>
        </div>
    );
}

Hero.propTypes = {
};

export default Hero;