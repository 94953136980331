import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import topBarNavStyle from "./TopBarNav.module.css";
import Constrainer from "../reusables/Constrainer.js";
import constrainerStyle from "../reusables/Constrainer.module.css";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import {
	IoIosMenu,
} from "react-icons/io";
import {
	RiHeartLine,
	RiSearchLine,
	RiShoppingCart2Line,
} from "react-icons/ri";
import { VscAccount } from "react-icons/vsc";
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
	EN,
	ES,
	useLanguageContext,
} from 'context/LanguageContext';
import Content from "components/gallery/Content.js";
import FSSC_logo from 'media-assets/logo.svg';
import Container from '@material-ui/core/Container';

const LanguageSelector = () => {
	const useStyles = makeStyles((theme) => ({
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
	}));

	const classes = useStyles();

	const [ language, setLanguage ] = useLanguageContext();

	return (
		<FormControl className={classes.formControl}>
			<InputLabel htmlFor="outlined-language-selector-simple">
				<Content
					contentKey={'lang-selector-label'}
					elementType={'span'}
				/>
			</InputLabel>
			<NativeSelect
				value={language}
				onChange={(event) => setLanguage(event.target.value)}
				inputProps={{
					name: 'Language',
					id: 'outlined-language-selector-simple',
				}}
			>
				<Content
					elementType={'option'}
					contentKey={'lang-selector-option-spanish'}
					value={ES}
				/>
				<Content
					elementType={'option'}
					contentKey={'lang-selector-option-english'}
					value={EN}
				/>
			</NativeSelect>
		</FormControl>
	);
};

const TopBarNav = () => {

	const [ anchorEl, setAnchorEl ] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<div className={topBarNavStyle.mobileMenu}>
				<img src={FSSC_logo} height={50} />

				<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
					<IoIosMenu className={topBarNavStyle.menuIcon} />
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<LanguageSelector />

					<MenuItem onClick={handleClose}>
						<NavLink
							to="#top"
							smooth
							activeClassName={topBarNavStyle.active}
							className={topBarNavStyle.mmMenuItem}
						>
							<Content
								elementType={'span'}
								contentKey={'nav-link-label-home'}
							/>
						</NavLink>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<NavLink
							to="/#about"
							smooth
							activeClassName={topBarNavStyle.active}
							className={topBarNavStyle.mmMenuItem}
						>
							<Content
								elementType={'span'}
								contentKey={'nav-link-label-about-us'}
							/>
						</NavLink>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<NavLink
							to="/#gallery"
							smooth
							activeClassName={topBarNavStyle.active}
							className={topBarNavStyle.mmMenuItem}
						>
							<Content
								elementType={'span'}
								contentKey={'footer-link-label-services'}
							/>
						</NavLink>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<NavLink
							to="/#team"
							smooth
							activeClassName={topBarNavStyle.active}
							className={topBarNavStyle.mmMenuItem}
						>
							<Content
								elementType={'span'}
								contentKey={'footer-link-label-team'}
							/>
						</NavLink>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<NavLink
							to="/#contact"
							smooth
							activeClassName={topBarNavStyle.active}
							className={topBarNavStyle.mmMenuItem}
						>
							<Content
								elementType={'span'}
								contentKey={'footer-link-label-contact-us'}
							/>
						</NavLink>
					</MenuItem>
				</Menu>
			</div>
			<div className={topBarNavStyle.headerOutline}>
				<div className={topBarNavStyle.header}>
					<div className={topBarNavStyle.navBar}>
						<Container className={topBarNavStyle.overrideConstrainer}>
							<img src={FSSC_logo} height={100} />

							<ul className={topBarNavStyle.navContainer}>
								<NavLink
									to="#top"
									smooth
									activeClassName={topBarNavStyle.active}
								>
									<Content
										elementType={'li'}
										contentKey={'nav-link-label-home'}
									/>
								</NavLink>
								<NavLink
									to="/#about"
									smooth
									activeClassName={topBarNavStyle.active}
								>
									<Content
										elementType={'li'}
										contentKey={'nav-link-label-about-us'}
									/>
								</NavLink>
								<NavLink
									to="/#gallery"
									smooth
									activeClassName={topBarNavStyle.active}
								>
									<Content
										elementType={'li'}
										contentKey={'footer-link-label-services'}
									/>
								</NavLink>
								<NavLink
									to="/#contact"
									smooth
									activeClassName={topBarNavStyle.active}
								>
									<Content
										elementType={'li'}
										contentKey={'footer-link-label-contact-us'}
									/>
								</NavLink>
								<NavLink
									to="/#team"
									smooth
									activeClassName={topBarNavStyle.active}
								>
									<Content
										elementType={'li'}
										contentKey={'footer-link-label-team'}
									/>
								</NavLink>
							</ul>
							<div className={topBarNavStyle.navContainerMain}>
								<div className={topBarNavStyle.languageSelector}>
									<LanguageSelector />
								</div>
							</div>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
}

TopBarNav.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default TopBarNav;