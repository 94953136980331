import cx from 'classnames';
import PropTypes from "prop-types";
import React from "react";

import Container from '@material-ui/core/Container';
import typography from 'styles/Typography.module.css';
import gallery from "./Gallery.module.css";
import Content from "components/gallery/Content.js";

const About = () => {
    return (
        <Container className={gallery.container} component="section">

            <Content
                contentKey={'about-us-header-main'}
                elementType={'Typography'}
                gutterBottom
                variant="h2"
                component="h2"
                className={cx(typography.header, typography.center)}
            />

            <Content
                contentKey={'about-us-header-1'}
                elementType={'Typography'}
                gutterBottom
                variant="h4"
                component="h4"
                className={cx(typography.headerSecondary, typography.center)}
            />

            <Content
                contentKey={'about-us-p1'}
                elementType={'Typography'}
                variant="body1"
                gutterBottom
                className={cx(typography.body, typography.paragraph)}
            />

            <Content
                contentKey={'about-us-p2'}
                elementType={'Typography'}
                variant="body1"
                gutterBottom
                className={cx(typography.body, typography.paragraph)}
            />

            <Content
                contentKey={'about-us-p3'}
                elementType={'Typography'}
                variant="body1"
                gutterBottom
                className={cx(typography.body, typography.paragraph)}
            />

        </Container>
    );
}

About.propTypes = {
};

export default About;