import cx from 'classnames';
import React, { useState } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      "&:focus": {
        outline: 'none',
      },
      width: '100%',
      '@media only screen and (min-width: 48em)': {
        width: '75%',
      },
      backgroundColor: 'transparent',
    },
  }));

const MuiModal = ({
    children,
    open,
    onClose,
    ...rest
}) => {
    const classes = useStyles();
    const rootRef = React.useRef(null);

    return (
        <div className={classes.root} ref={rootRef}>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.75)" } }}
            >
                <div className={classes.paper}>
                    {children}
                </div>
            </Modal>
        </div>
    );
}

MuiModal.propTypes = {};

export default MuiModal;