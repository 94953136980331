import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from 'react';

import contactUsStyles from "./Contact.module.css";

import { makeStyles } from '@material-ui/core/styles';
import MUICard from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import typography from 'styles/Typography.module.css';

import topBarNav from 'layouts/default-layout-components/TopBarNav.module.css';
import gallery from "./Gallery.module.css";
import Container from '@material-ui/core/Container';
import Content from "components/gallery/Content.js";

const Contact = () => {

    const useStyles = makeStyles({
        media: {
            height: 140,
        },
    });

    const classes = useStyles();

    return (
        <Container className={gallery.container} component="section">
            <Content
                elementType={"Typography"}
                contentKey={'contact-us-header-main'}
                gutterBottom
                variant="h2"
                component="h2"
                className={cx(typography.header, typography.center)}
            />

            <Divider variant="middle" />

            <div className={contactUsStyles.panel}>
                <MUICard className={cx(classes.root, contactUsStyles.section, contactUsStyles.medium)}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.760001012586!2d-73.92282568470415!3d40.70128304620824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25c1b85751f7f%3A0x2a3daddaf786cfd5!2s236%20Stockholm%20St%20%233F%2C%20Brooklyn%2C%20NY%2011237!5e0!3m2!1sen!2sus!4v1617769114396!5m2!1sen!2sus"
                        allowfullscreen=""
                        loading="lazy"
                        className={contactUsStyles.map}
                    />
                </MUICard>
                <MUICard className={cx(classes.root, contactUsStyles.section, contactUsStyles.medium)}>
                    <div className={contactUsStyles.content}>
                        <div className={contactUsStyles.body}>
                            <Typography component="h1" variant="h3" className={topBarNav.brandContact} >
                                Fast Solutions Services Corp.
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                236 Stockholm St. 3F | Brooklyn, New York 11237
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                347 - 296 - 9125 | luis@fastsolutionsservices.co
                            </Typography>
                        </div>
                    </div>
                </MUICard>
            </div>
        </Container>
    );
}

Contact.propTypes = {
};

export default Contact;