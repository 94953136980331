import cx from 'classnames';
import PropTypes from "prop-types";
import React from "react";
import cardStyle from "./Card.module.css";
import generalStyles from "styles/general.module.css";
import {
    MagnifierContainer,
    MagnifierPreview,
    MagnifierZoom,
} from "react-image-magnifiers";

import { makeStyles } from '@material-ui/core/styles';
import MUICard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

const Card = ({
    productId,
    imageUrl,
    alt_label = "",
    title = "",
    magnify = false,
    triggerModal = () => { },
}) => {

    const classes = useStyles();

    return (
        <div className={cx(cardStyle.card, generalStyles.flexColumn)}>
            <MUICard className={classes.root}>
                <CardMedia>
                    {magnify
                        ? (
                            <MagnifierContainer>
                                <div className="example-class">
                                    <MagnifierPreview imageSrc={imageUrl} autoInPlace={true} />
                                    <MagnifierZoom style={{
                                        height: "400px",
                                        width: "400px",
                                        position: "absolute",
                                        left: "-12.5%",
                                        zIndex: "1000",
                                    }} imageSrc={imageUrl} />
                                </div>
                            </MagnifierContainer>
                        )
                        : (
                            <div className={cardStyle.image}>
                                <img src={imageUrl} alt={alt_label} className={cardStyle.image} />
                            </div>
                        )}
                </CardMedia>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                </CardContent>

                <CardActionArea>
                    <CardActions>
                        <Button size="medium"
                            onClick={triggerModal}
                        >
                            View
                        </Button>
                        <Button size="medium" href={`/product/${productId}`}>
                            Buy
                        </Button>
                    </CardActions>
                </CardActionArea>
            </MUICard>
        </div>
    );
}

Card.propTypes = {
    imageUrl: PropTypes.string,
    alt_label: PropTypes.string,
    title: PropTypes.string,
};

export default Card;