import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import modalStyles from "./Modal.module.css";

const Modal = ({
    active = false,
    children = [],
}) => {
    return (
        <div id="myModal" className={cx(modalStyles.modal, active ? modalStyles.active : null)}>
            {children}
        </div>
    );
};

Modal.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.element,
    ]).isRequired,
};

export default Modal;