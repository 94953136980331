import cx from 'classnames';
import React, { useState } from 'react';
import PropTypes from "prop-types";

import { map } from "lodash";

import Card from "../components/gallery/Card.js";
import Constrainer from "../layouts/reusables/Constrainer.js";
import Button, { secondary, action } from "../layouts/reusables/forms/Button.js";
import pageStyle from "./Page.module.css";
import galleryStyles from "./Gallery.module.css";
import modalStyles from "../layouts/reusables/Modal.module.css";
import constrainerStyle from "../layouts/reusables/Constrainer.module.css";
import { Products } from "sample_data/Products.js";
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import contactUs from 'pages/Contact.module.css';
import typography from 'styles/Typography.module.css';

import MuiModal from './MuiModal.js';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Content from "components/gallery/Content.js";

import BathroomThumb from "media-assets/Bathroom/1.jpg";
import CielingThumb from "media-assets/Cieling/1.jpg";
import DryWallThumb from "media-assets/Dry-wall/1.jpg";
import FloorThumb from "media-assets/Floor/1.jpg";
import HomeInteriorThumb from "media-assets/Home-Interior/1.jpg";
import OfficeSpaceThumb from "media-assets/Office-Space/1.jpg";
import WindowThumb from "media-assets/Windows/1.jpg";
import WoodWorkThumb from "media-assets/Wood-work/1.jpg";

import CommercialThumb from "media-assets/Commercial/23.jpg";

import CommercialDialog2 from "media-assets/Commercial/2.jpg";
import CommercialDialog3 from "media-assets/Commercial/3.jpg";
import CommercialDialog4 from "media-assets/Commercial/4.jpg";
import CommercialDialog5 from "media-assets/Commercial/5.jpg";
import CommercialDialog6 from "media-assets/Commercial/6.jpg";
import CommercialDialog7 from "media-assets/Commercial/7.jpg";
import CommercialDialog8 from "media-assets/Commercial/8.jpg";
import CommercialDialog9 from "media-assets/Commercial/9.jpg";
import CommercialDialog10 from "media-assets/Commercial/10.jpg";
import CommercialDialog11 from "media-assets/Commercial/11.jpg";
import CommercialDialog12 from "media-assets/Commercial/12.jpg";
import CommercialDialog13 from "media-assets/Commercial/13.jpg";
import CommercialDialog14 from "media-assets/Commercial/14.jpg";
import CommercialDialog15 from "media-assets/Commercial/15.jpg";
import CommercialDialog16 from "media-assets/Commercial/16.jpg";
import CommercialDialog17 from "media-assets/Commercial/17.jpg";
import CommercialDialog18 from "media-assets/Commercial/18.jpg";
import CommercialDialog19 from "media-assets/Commercial/19.jpg";
import CommercialDialog20 from "media-assets/Commercial/20.jpg";
import CommercialDialog21 from "media-assets/Commercial/21.jpg";
import CommercialDialog22 from "media-assets/Commercial/22.jpg";
import CommercialDialog23 from "media-assets/Commercial/23.jpg";
import CommercialDialog24 from "media-assets/Commercial/24.jpg";
import CommercialDialog25 from "media-assets/Commercial/25.jpg";
import CommercialDialog26 from "media-assets/Commercial/26.jpg";
import CommercialDialog27 from "media-assets/Commercial/27.jpg";
import CommercialDialog28 from "media-assets/Commercial/28.jpg";
import CommercialDialog29 from "media-assets/Commercial/29.jpg";
import CommercialDialog30 from "media-assets/Commercial/30.jpg";
import CommercialDialog31 from "media-assets/Commercial/31.jpg";
import CommercialDialog32 from "media-assets/Commercial/32.jpg";

import {
	HiOutlineChevronLeft,
	HiOutlineChevronRight,
} from "react-icons/hi";

import BathroomDialog1 from "media-assets/Bathroom/1.jpg";
import BathroomDialog2 from "media-assets/Bathroom/2.jpg";
import BathroomDialog3 from "media-assets/Bathroom/3.jpg";
import BathroomDialog4 from "media-assets/Bathroom/4.jpg";
import BathroomDialog5 from "media-assets/Bathroom/5.jpg";
import BathroomDialog6 from "media-assets/Bathroom/6.jpg";
import BathroomDialog7 from "media-assets/Bathroom/7.jpg";
import BathroomDialog8 from "media-assets/Bathroom/8.jpg";
import BathroomDialog9 from "media-assets/Bathroom/9.jpg";
import BathroomDialog10 from "media-assets/Bathroom/10.jpg";
import BathroomDialog11 from "media-assets/Bathroom/11.jpg";
import BathroomDialog12 from "media-assets/Bathroom/12.jpg";
import BathroomDialog13 from "media-assets/Bathroom/13.jpg";
import BathroomDialog14 from "media-assets/Bathroom/14.jpg";
import BathroomDialog15 from "media-assets/Bathroom/15.jpg";
import BathroomDialog16 from "media-assets/Bathroom/16.jpg";

import CeilingDialog1 from "media-assets/Cieling/1.jpg";
import CeilingDialog2 from "media-assets/Cieling/2.jpg";
import CeilingDialog3 from "media-assets/Cieling/3.jpg";
import CeilingDialog4 from "media-assets/Cieling/4.jpg";
import CeilingDialog5 from "media-assets/Cieling/5.jpg";
import CeilingDialog6 from "media-assets/Cieling/6.jpg";
import CeilingDialog7 from "media-assets/Cieling/7.jpg";
import CeilingDialog8 from "media-assets/Cieling/8.jpg";
import CeilingDialog9 from "media-assets/Cieling/9.jpg";
import CeilingDialog10 from "media-assets/Cieling/10.jpg";
import CeilingDialog11 from "media-assets/Cieling/11.jpg";


import DryWallDialog1 from "media-assets/Dry-wall/1.jpg";
import DryWallDialog2 from "media-assets/Dry-wall/2.jpg";
import DryWallDialog3 from "media-assets/Dry-wall/3.jpg";
import DryWallDialog4 from "media-assets/Dry-wall/4.jpg";
import DryWallDialog5 from "media-assets/Dry-wall/5.jpg";
import DryWallDialog6 from "media-assets/Dry-wall/6.jpg";
import DryWallDialog7 from "media-assets/Dry-wall/7.jpg";
import DryWallDialog8 from "media-assets/Dry-wall/8.jpg";
import DryWallDialog9 from "media-assets/Dry-wall/9.jpg";
import DryWallDialog10 from "media-assets/Dry-wall/10.jpg";
import DryWallDialog11 from "media-assets/Dry-wall/11.jpg";
import DryWallDialog12 from "media-assets/Dry-wall/12.jpg";
import DryWallDialog13 from "media-assets/Dry-wall/13.jpg";
import DryWallDialog14 from "media-assets/Dry-wall/14.jpg";
import DryWallDialog15 from "media-assets/Dry-wall/15.jpg";
import DryWallDialog16 from "media-assets/Dry-wall/16.jpg";
import DryWallDialog17 from "media-assets/Dry-wall/17.jpg";
import DryWallDialog18 from "media-assets/Dry-wall/18.jpg";
import DryWallDialog19 from "media-assets/Dry-wall/19.jpg";

import FloorDialog1 from "media-assets/Floor/1.jpg";
import FloorDialog2 from "media-assets/Floor/2.jpg";
import FloorDialog3 from "media-assets/Floor/3.jpg";
import FloorDialog4 from "media-assets/Floor/4.jpg";
import FloorDialog5 from "media-assets/Floor/5.jpg";
import FloorDialog6 from "media-assets/Floor/6.jpg";
import FloorDialog7 from "media-assets/Floor/7.jpg";
import FloorDialog8 from "media-assets/Floor/8.jpg";
import FloorDialog9 from "media-assets/Floor/9.jpg";
import FloorDialog10 from "media-assets/Floor/10.jpg";
import FloorDialog11 from "media-assets/Floor/11.jpg";
import FloorDialog12 from "media-assets/Floor/12.jpg";

import HomeInteriorDialog1 from "media-assets/Home-Interior/1.jpg";
import HomeInteriorDialog2 from "media-assets/Home-Interior/2.jpg";
import HomeInteriorDialog3 from "media-assets/Home-Interior/3.jpg";
import HomeInteriorDialog4 from "media-assets/Home-Interior/4.jpg";
import HomeInteriorDialog5 from "media-assets/Home-Interior/5.jpg";
import HomeInteriorDialog6 from "media-assets/Home-Interior/6.jpg";
import HomeInteriorDialog7 from "media-assets/Home-Interior/7.jpg";
import HomeInteriorDialog8 from "media-assets/Home-Interior/8.jpg";
import HomeInteriorDialog9 from "media-assets/Home-Interior/9.jpg";
import HomeInteriorDialog10 from "media-assets/Home-Interior/10.jpg";
import HomeInteriorDialog11 from "media-assets/Home-Interior/11.jpg";
import HomeInteriorDialog12 from "media-assets/Home-Interior/12.jpg";
import HomeInteriorDialog13 from "media-assets/Home-Interior/13.jpg";
import HomeInteriorDialog14 from "media-assets/Home-Interior/14.jpg";
import HomeInteriorDialog15 from "media-assets/Home-Interior/15.jpg";
import HomeInteriorDialog16 from "media-assets/Home-Interior/16.jpg";
import HomeInteriorDialog17 from "media-assets/Home-Interior/17.jpg";
import HomeInteriorDialog18 from "media-assets/Home-Interior/18.jpg";
import HomeInteriorDialog19 from "media-assets/Home-Interior/19.jpg";
import HomeInteriorDialog20 from "media-assets/Home-Interior/20.jpg";
import HomeInteriorDialog21 from "media-assets/Home-Interior/21.jpg";
import HomeInteriorDialog22 from "media-assets/Home-Interior/22.jpg";
import HomeInteriorDialog23 from "media-assets/Home-Interior/23.jpg";
import HomeInteriorDialog24 from "media-assets/Home-Interior/24.jpg";
import HomeInteriorDialog25 from "media-assets/Home-Interior/25.jpg";
import HomeInteriorDialog26 from "media-assets/Home-Interior/26.jpg";
import HomeInteriorDialog27 from "media-assets/Home-Interior/27.jpg";
import HomeInteriorDialog28 from "media-assets/Home-Interior/28.jpg";
import HomeInteriorDialog29 from "media-assets/Home-Interior/29.jpg";
import HomeInteriorDialog30 from "media-assets/Home-Interior/30.jpg";
import HomeInteriorDialog31 from "media-assets/Home-Interior/31.jpg";
import HomeInteriorDialog32 from "media-assets/Home-Interior/32.jpg";

import OfficeSpaceDialog1 from "media-assets/Office-Space/1.jpg";
import OfficeSpaceDialog2 from "media-assets/Office-Space/2.jpg";
import OfficeSpaceDialog3 from "media-assets/Office-Space/3.jpg";
import OfficeSpaceDialog4 from "media-assets/Office-Space/4.jpg";
import OfficeSpaceDialog5 from "media-assets/Office-Space/5.jpg";
import OfficeSpaceDialog6 from "media-assets/Office-Space/6.jpg";
import OfficeSpaceDialog7 from "media-assets/Office-Space/7.jpg";
import OfficeSpaceDialog8 from "media-assets/Office-Space/8.jpg";
import OfficeSpaceDialog9 from "media-assets/Office-Space/9.jpg";
import OfficeSpaceDialog10 from "media-assets/Office-Space/10.jpg";
import OfficeSpaceDialog11 from "media-assets/Office-Space/11.jpg";

import WindowsDialog1 from "media-assets/Windows/1.jpg";
import WindowsDialog2 from "media-assets/Windows/2.jpg";
import WindowsDialog3 from "media-assets/Windows/3.jpg";
import WindowsDialog4 from "media-assets/Windows/4.jpg";
import WindowsDialog5 from "media-assets/Windows/5.jpg";

import WoodWorkDialog1 from "media-assets/Wood-work/1.jpg";
import WoodWorkDialog2 from "media-assets/Wood-work/2.jpg";
import WoodWorkDialog3 from "media-assets/Wood-work/3.jpg";
import WoodWorkDialog4 from "media-assets/Wood-work/4.jpg";
import WoodWorkDialog5 from "media-assets/Wood-work/5.jpg";
import WoodWorkDialog6 from "media-assets/Wood-work/6.jpg";
import WoodWorkDialog7 from "media-assets/Wood-work/7.jpg";
import WoodWorkDialog8 from "media-assets/Wood-work/8.jpg";

import {
	IoMdClose,
} from "react-icons/io";

const SERVICES = [
	{
		url: CommercialThumb,
		title: 'gallery-services-thumb-label-commercial',
		key: "commercial",
	},
	{
		url: HomeInteriorThumb,
		title: 'gallery-services-thumb-label-home-interior',
		key: "home-interior",
	},
	{
		url: OfficeSpaceThumb,
		title: 'gallery-services-thumb-label-office-space',
		key: "office-space",
	},
	{
		url: BathroomThumb,
		title: 'gallery-services-thumb-label-bathroom',
		key: "bathroom",
	},
	{
		url: FloorThumb,
		title: 'gallery-services-thumb-label-dry-wall-floors',
		key: "floors",
	},
	{
		url: CielingThumb,
		title: 'gallery-services-thumb-label-cieling',
		key: "cieling",
	},
	{
		url: DryWallThumb,
		title: 'gallery-services-thumb-label-dry-wall',
		key: "dry-wall",
	},
	{
		url: WindowThumb,
		title: 'gallery-services-thumb-label-windows',
		key: "windows",
	},
	{
		url: WoodWorkThumb,
		title: 'gallery-services-thumb-label-wood-work',
		key: "wood-work",
	},
];

const CommercialDialogAssests = [
	{
		label: '',
		imgPath: CommercialDialog2,
	},
	{
		label: '',
		imgPath: CommercialDialog3,
	},
	{
		label: '',
		imgPath: CommercialDialog4,
	},
	{
		label: '',
		imgPath: CommercialDialog5,
	},
	{
		label: '',
		imgPath: CommercialDialog6,
	},
	{
		label: '',
		imgPath: CommercialDialog7,
	},
	{
		label: '',
		imgPath: CommercialDialog8,
	},
	{
		label: '',
		imgPath: CommercialDialog9,
	},
	{
		label: '',
		imgPath: CommercialDialog10,
	},
	{
		label: '',
		imgPath: CommercialDialog11,
	},
	{
		label: '',
		imgPath: CommercialDialog12,
	},
	{
		label: '',
		imgPath: CommercialDialog13,
	},
	{
		label: '',
		imgPath: CommercialDialog14,
	},
	{
		label: '',
		imgPath: CommercialDialog15,
	},
	{
		label: '',
		imgPath: CommercialDialog16,
	},
	{
		label: '',
		imgPath: CommercialDialog17,
	},
	{
		label: '',
		imgPath: CommercialDialog18,
	},
	{
		label: '',
		imgPath: CommercialDialog19,
	},
	{
		label: '',
		imgPath: CommercialDialog20,
	},
	{
		label: '',
		imgPath: CommercialDialog21,
	},
	{
		label: '',
		imgPath: CommercialDialog22,
	},
	{
		label: '',
		imgPath: CommercialDialog23,
	},
	{
		label: '',
		imgPath: CommercialDialog24,
	},
	{
		label: '',
		imgPath: CommercialDialog25,
	},
	{
		label: '',
		imgPath: CommercialDialog26,
	},
	{
		label: '',
		imgPath: CommercialDialog27,
	},
	{
		label: '',
		imgPath: CommercialDialog28,
	},
	{
		label: '',
		imgPath: CommercialDialog29,
	},
	{
		label: '',
		imgPath: CommercialDialog30,
	},
	{
		label: '',
		imgPath: CommercialDialog31,
	},
	{
		label: '',
		imgPath: CommercialDialog32,
	},
];

const BathroomDialogAssests = [
	{
		label: '',
		imgPath: BathroomDialog1,
	},
	{
		label: '',
		imgPath: BathroomDialog2,
	},
	{
		label: '',
		imgPath: BathroomDialog3,
	},
	//
	{
		label: '',
		imgPath: BathroomDialog4,
	},
	{
		label: '',
		imgPath: BathroomDialog5,
	},
	{
		label: '',
		imgPath: BathroomDialog6,
	},
	{
		label: '',
		imgPath: BathroomDialog7,
	},
	{
		label: '',
		imgPath: BathroomDialog8,
	},
	{
		label: '',
		imgPath: BathroomDialog9,
	},
	{
		label: '',
		imgPath: BathroomDialog10,
	},
	{
		label: '',
		imgPath: BathroomDialog11,
	},
	{
		label: '',
		imgPath: BathroomDialog12,
	},
	{
		label: '',
		imgPath: BathroomDialog13,
	},
	{
		label: '',
		imgPath: BathroomDialog14,
	},
	{
		label: '',
		imgPath: BathroomDialog15,
	},
	{
		label: '',
		imgPath: BathroomDialog16,
	},
];

const CielingDialogAssests = [
	{
		label: '',
		imgPath: CeilingDialog1,
	},
	{
		label: '',
		imgPath: CeilingDialog2,
	},
	{
		label: '',
		imgPath: CeilingDialog3,
	},
	{
		label: '',
		imgPath: CeilingDialog4,
	},
	{
		label: '',
		imgPath: CeilingDialog5,
	},
	{
		label: '',
		imgPath: CeilingDialog6,
	},
	{
		label: '',
		imgPath: CeilingDialog7,
	},
	{
		label: '',
		imgPath: CeilingDialog8,
	},
	{
		label: '',
		imgPath: CeilingDialog9,
	},
	{
		label: '',
		imgPath: CeilingDialog10,
	},
	{
		label: '',
		imgPath: CeilingDialog11,
	},
];

const DryWallDialogAssests = [
	{
		label: '',
		imgPath: DryWallDialog1,
	},
	{
		label: '',
		imgPath: DryWallDialog2,
	},
	{
		label: '',
		imgPath: DryWallDialog3,
	},
	{
		label: '',
		imgPath: DryWallDialog4,
	},
	{
		label: '',
		imgPath: DryWallDialog5,
	},
	{
		label: '',
		imgPath: DryWallDialog6,
	},
	{
		label: '',
		imgPath: DryWallDialog7,
	},
	{
		label: '',
		imgPath: DryWallDialog8,
	},
	{
		label: '',
		imgPath: DryWallDialog9,
	},
	{
		label: '',
		imgPath: DryWallDialog10,
	},
	{
		label: '',
		imgPath: DryWallDialog11,
	},
	{
		label: '',
		imgPath: DryWallDialog12,
	},
	{
		label: '',
		imgPath: DryWallDialog13,
	},
	{
		label: '',
		imgPath: DryWallDialog14,
	},
	{
		label: '',
		imgPath: DryWallDialog15,
	},
	{
		label: '',
		imgPath: DryWallDialog16,
	},
	{
		label: '',
		imgPath: DryWallDialog17,
	},
	{
		label: '',
		imgPath: DryWallDialog18,
	},
	{
		label: '',
		imgPath: DryWallDialog19,
	},
];

const FloorDialogAssests = [
	{
		label: '',
		imgPath: FloorDialog1,
	},
	{
		label: '',
		imgPath: FloorDialog2,
	},
	{
		label: '',
		imgPath: FloorDialog3,
	},
	{
		label: '',
		imgPath: FloorDialog4,
	},
	{
		label: '',
		imgPath: FloorDialog5,
	},
	{
		label: '',
		imgPath: FloorDialog6,
	},
	{
		label: '',
		imgPath: FloorDialog7,
	},
	{
		label: '',
		imgPath: FloorDialog8,
	},
	{
		label: '',
		imgPath: FloorDialog9,
	},
	{
		label: '',
		imgPath: FloorDialog10,
	},
	{
		label: '',
		imgPath: FloorDialog11,
	},
	{
		label: '',
		imgPath: FloorDialog12,
	},
];

const HomeInteriorDialogAssests = [
	{
		label: '',
		imgPath: HomeInteriorDialog1,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog2,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog3,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog4,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog5,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog6,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog7,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog8,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog9,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog10,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog11,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog12,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog13,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog14,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog15,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog16,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog17,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog18,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog19,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog20,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog21,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog22,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog23,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog24,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog25,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog26,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog27,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog28,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog29,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog30,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog31,
	},
	{
		label: '',
		imgPath: HomeInteriorDialog32,
	},
];

const OfficeSpaceDialogAssests = [
	{
		label: '',
		imgPath: OfficeSpaceDialog1,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog2,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog3,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog4,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog5,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog6,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog7,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog8,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog9,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog10,
	},
	{
		label: '',
		imgPath: OfficeSpaceDialog11,
	},
];

const WindowsDialogAssests = [
	{
		label: '',
		imgPath: WindowsDialog1,
	},
	{
		label: '',
		imgPath: WindowsDialog2,
	},
	{
		label: '',
		imgPath: WindowsDialog3,
	},
	{
		label: '',
		imgPath: WindowsDialog4,
	},
	{
		label: '',
		imgPath: WindowsDialog5,
	},
];

const WoodWorkDialogAssests = [
	{
		label: '',
		imgPath: WoodWorkDialog1,
	},
	{
		label: '',
		imgPath: WoodWorkDialog2,
	},
	{
		label: '',
		imgPath: WoodWorkDialog3,
	},
	{
		label: '',
		imgPath: WoodWorkDialog4,
	},
	{
		label: '',
		imgPath: WoodWorkDialog5,
	},
	{
		label: '',
		imgPath: WoodWorkDialog6,
	},
	{
		label: '',
		imgPath: WoodWorkDialog7,
	},
	{
		label: '',
		imgPath: WoodWorkDialog8,
	},
];

const ModalViewItems = {
	[ 'commercial' ]: CommercialDialogAssests,
	[ 'home-interior' ]: HomeInteriorDialogAssests,
	[ 'office-space' ]: OfficeSpaceDialogAssests,
	bathroom: BathroomDialogAssests,
	cieling: CielingDialogAssests,
	[ 'dry-wall' ]: DryWallDialogAssests,
	floors: FloorDialogAssests,
	windows: WindowsDialogAssests,
	[ "wood-work"]: WoodWorkDialogAssests,
};

const Slider = ({ items }) => {
	const [ index, setIndex ] = useState(0);

	const label = items[ index ]?.label;

	return (
		<>
			{label && <Typography
				component="h3"
				variant="h6"
				color="inherit"
				className={galleryStyles.slideTitle}
			>
				{label}
			</Typography>}
			<Carousel
				onChange={(newIndex) => { setIndex(newIndex); }}
				renderArrowPrev={(onClickHandler, hasPrev, label) =>
					hasPrev && <button type="button" onClick={onClickHandler} title={label} className={cx(galleryStyles.arrowButton, galleryStyles.left)}>
						<HiOutlineChevronLeft />
					</button>
				}
				renderArrowNext={(onClickHandler, hasNext, label) =>
					hasNext && <button type="button" onClick={onClickHandler} title={label} className={cx(galleryStyles.arrowButton, galleryStyles.right)}>
						<HiOutlineChevronRight />
					</button>
				}
			>
				{map(items, item => (
					<div key={item.label} className={galleryStyles.sliderContent}>
						<img src={item.imgPath} />
					</div>))}
			</Carousel>
		</>
	);
};

const Gallery = ({ }) => {
	const [ open, setOpen ] = useState(false);
	const [ modalItems, setModalItems ] = useState(null);

	const handleOpen = (key) => {
		setOpen(true);
		setModalItems(ModalViewItems[ key ])
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Container className={galleryStyles.container} component="section">
			<Content
				elementType={"Typography"}
				contentKey={'gallery-header-main'}
				gutterBottom
				variant="h2"
				component="h2"
				className={cx(typography.header, typography.center)}
			/>

			<Content
				elementType={"Typography"}
				contentKey={'gallery-header-sub1'}
				gutterBottom
				variant="body1"
				className={cx(typography.body, typography.paragraph, typography.center)}
			/>
			<Content
				elementType={"Typography"}
				contentKey={'gallery-header-sub2'}
				gutterBottom
				variant="h2"
				component="h2"
				className={cx(typography.body, typography.paragraph, typography.center)}
			/>

			<Content
				elementType={"Typography"}
				contentKey={'gallery-header-sub3'}
				gutterBottom
				variant="h2"
				component="h2"
				className={cx(typography.body, typography.paragraph, typography.center)}
			/>

			<MuiModal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div className={galleryStyles.closeButton}>
					<Button onClick={handleClose} className={galleryStyles.closeBtn}>
						<IoMdClose className={galleryStyles.closeIcon} />
					</Button>
				</div>
				<Slider items={modalItems} />
			</MuiModal>
			<div className={galleryStyles.imagesContainer}>
				{map(SERVICES, ({
					title,
					url,
					key,
				}) => (
					<ButtonBase
						key={title}
						className={galleryStyles.imageContainer}
						onClick={e => handleOpen(key)}
					>
						<div
							className={galleryStyles.imageSrc}
							style={{
								backgroundImage: `url(${url})`,
							}}
						/>
						<div className={galleryStyles.imageBackdrop} />
						<div className={galleryStyles.imageButton}>
							<Typography
								component="h3"
								variant="h6"
								color="inherit"
								className={galleryStyles.imageTitle}
							>
								<Content
									contentKey={title}
									elementType={'span'}
								/>
								<div className={galleryStyles.imageMarked} />
							</Typography>
						</div>
					</ButtonBase>
				))}
			</div>
		</Container>
	);
}

Gallery.propTypes = {};

export default Gallery;