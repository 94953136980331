import cx from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import homeStyle from "./Home.module.css";

import Hero from './Hero.js';
import About from './About.js';
import Gallery from './Gallery.js';
import Contact from './Contact.js';
import OurTeam from './OurTeam.js';

const Home = () => {

    return (
        <div className={homeStyle.container}>
            <div id='hero'>
                <Hero />
            </div>
            <div id='about'>
                <About />
            </div>
            <div id='gallery'>
                <Gallery />
            </div>
            <div id='team'>
                <OurTeam />
            </div>
            <div id='contact'>
                <Contact />
            </div>
        </div>
    );
}

Home.propTypes = {
};

export default Home;