import React, {
	useState,
	useContext,
} from 'react';

export const EN = 'en';
export const ES = 'es';

export const LanguageContext = React.createContext(() => console.log("LanguageContext used before it was ready"));
export const useLanguageContext = () => useContext(LanguageContext);

const Language = ({
	children,
}) => {
	const [ language, setLanguage ] = useState(EN);

	return (
		<LanguageContext.Provider value={[ language, setLanguage ]}>
			{children}
		</LanguageContext.Provider>
	);
};

export default Language;
