import cx from 'classnames';
import PropTypes from "prop-types";
import React from "react";
import buttonStyles from "./Button.module.css";

export const primary = 'primary';
export const secondary = 'secondary';
export const action = 'action';

const Button = ({
    variant = primary,
    title = '',
    children = [],
    type = '',
    ...rest
}) => {

    const content = title ? title : children;
    const variantStyle = type === 'submit' ? primary : variant;

    return (
        <button
            className={cx(buttonStyles.base, buttonStyles[variantStyle])}
            {...rest}
        >
            {content}
        </button>
    );
}

Button.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    children: [],
    type: PropTypes.string,
};

Button.defualtProps = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.element,
    ]).isRequired,
};

export default Button;